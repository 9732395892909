import React from "react"
import Helmet from "react-helmet"
import ReactGA from "react-ga"
//
import * as styles from "./style.module.scss"
import "styles/global.scss"

ReactGA.initialize("UA-52229310-3")

export default ({ path, pageContext, children }) => {
  ReactGA.pageview(path)

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "ja",
        }}
        title="Baw | 住宅街の一角にある美容室・美容院 | 札幌市清田区里塚"
        meta={[
          {
            name: "description",
            content:
              "清田区住宅街の一角にある美容室。完全予約制でカウンセリングから仕上げまでマンツーマンで担当いたします。『美術館のような美容室』をテーマにした空間。リラックス、集中、そして刺激が共存するような場所。忙しい日常から離れ、ご自身の美意識に目を向ける時間を過ごせる場所。営業時間10:00〜20:00。札幌市清田区里塚1条2丁目7-3",
          },
          {
            name: "keywords",
            content:
              "札幌,清田区,里塚,美容室,美容院,カット,トリートメント,カラー,ヘッドスパ",
          },
          {
            property: "og:title",
            content:
              "Baw | 住宅街の一角にある美容室・美容院 | 札幌市清田区里塚",
          },
          { property: "og:type", content: "website" },
          { property: "og:url", content: "http://baw.salon/" },
          { property: "og:image", content: "http://baw.salon/images/ogp.jpg" },
          {
            property: "og:description",
            content:
              "清田区住宅街の一角にある美容室。完全予約制でカウンセリングから仕上げまでマンツーマンで担当いたします。『美術館のような美容室』をテーマにした空間。リラックス、集中、そして刺激が共存するような場所。忙しい日常から離れ、ご自身の美意識に目を向ける時間を過ごせる場所。営業時間10:00〜20:00。札幌市清田区里塚1条2丁目7-3",
          },
        ]}
      />
      <div>{children}</div>
    </>
  )
}
